import {
  ParticipantStep,
  ListParticipantStepsResponse,
} from '@wix/ambassador-challenges-v1-participant/types';
import { UpdateParticipantStepStatusPayload } from '../ParticipantStepsDataProvider/ParticipantStepsContext';
import { useControllerData } from '../main/controllerContext';
import { ParticipantSection } from '../../types/v3Types';

export interface IParticipantSectionsContext {
  selectedSection?: ParticipantSection;
  isListParticipantSectionsRequestInProgress: boolean;
  listParticipantSections: ParticipantSection[];
  participantSteps: ListParticipantStepsResponse;
  updateParticipantSections(): void;
  selectedStep?: ParticipantStep;
  updateParticipantSteps(
    currentDate?: Date,
    includeAll?: boolean,
  ): Promise<void>;
  isParticipantStepsLoading: boolean;
  updateParticipantStepStatus(
    payload: UpdateParticipantStepStatusPayload,
  ): void;
  requestParticipantSection(
    sectionId: string,
    sections: ParticipantSection[],
  ): Promise<void>;
  requestParticipantStep(
    stepId: string,
    steps: ParticipantStep[],
  ): Promise<void>;
  generateStreamUrl(programId: string, stepId: string): Promise<any>;
}

export const participantSectionsContextDefaultValue: IParticipantSectionsContext =
  {
    isParticipantStepsLoading: false,
    updateParticipantStepStatus(): void {},
    updateParticipantSteps(): Promise<void> {
      return Promise.resolve(undefined);
    },
    selectedSection: undefined,
    isListParticipantSectionsRequestInProgress: false,
    listParticipantSections: null,
    participantSteps: null,
    updateParticipantSections: null,
    requestParticipantSection: null,
    requestParticipantStep: null,
    generateStreamUrl: null,
  };

export const useSections = () => {
  const props = useControllerData();
  return {
    selectedSection: props.selectedSection,
    participantSteps: props.participantSteps,
    isListParticipantSectionsRequestInProgress:
      props.isListParticipantSectionsRequestInProgress,
    listParticipantSections: props.listParticipantSections,
    updateParticipantSections: props.updateParticipantSections,
    selectedStep: props.selectedStep,
    updateParticipantSteps: props.updateParticipantSteps,
    isParticipantStepsLoading: props.isParticipantStepsLoading,
    updateParticipantStepStatus: props.updateParticipantStepStatus,
    requestParticipantSection: props.requestParticipantSection,
    requestParticipantStep: props.requestParticipantStep,
    generateStreamUrl: props.generateStreamUrl,
  };
};
